<template>
    <router-view />
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { notification } from '@/helpers/notification';
import fileSaver from '@/helpers/fileSaver';
import { RequestStatusEnum } from '@/helpers/consts';

export default {
    computed: {
        ...mapGetters('base', {
            selectedProduct: 'selectedProduct',
        }),
        ...mapGetters('gestao', {
            exportTitulosFromOutgoFile: 'exportTitulosFromOutgoFile',
            exportTitulosFromOutgoFileRequestStatus: 'exportTitulosFromOutgoFileRequestStatus',
            exportFromFactoryOutgoFile: 'exportFromFactoryOutgoFile',
            exportFromFactoryRequestStatus: 'exportFromFactoryRequestStatus',
        }),
    },
    methods: {
        ...mapActions('gestao', {
            updateBaseData: 'updateBaseData',
        }),
        saveFileHandler({ fileName, fileObj }) {
            notification(
                this.$dialog.notify.success,
                this.$t('outgo.exporttitles.success', { msg: fileName })
            );
            return fileSaver(fileObj, fileObj.type, fileName);
        },
    },
    created() {
        this.updateBaseData({
            selectedProduct: { ...this.selectedProduct },
        });
    },
    watch: {
        exportTitulosFromOutgoFileRequestStatus(newValue) {
            if (newValue === RequestStatusEnum.SUCCESS) {
                const meta = this.exportTitulosFromOutgoFile.meta;
                const fileName = `titulos${
                    meta.type === 'eligibles' ? 'elegiveis' : 'naoelegiveis'
                }_desembolso${meta.outgoId}.xlsx`;
                this.saveFileHandler({ fileName, fileObj: this.exportTitulosFromOutgoFile.file });
            }
            if (newValue.type === RequestStatusEnum.ERROR) {
                notification(
                    this.$dialog.notify.error,
                    this.$t('outgo.exporttitles.error', newValue.message)
                );
                return RequestStatusEnum.ERROR;
            }
        },
        exportFromFactoryRequestStatus(newValue) {
            if (newValue === RequestStatusEnum.SUCCESS) {
                const meta = this.exportFromFactoryOutgoFile.meta;
                const fileName = `clientes${
                    meta.type === 'eligibles' ? 'elegiveis' : 'naoelegiveis'
                }_desembolso${meta.outgoId}.xlsx`;
                this.saveFileHandler({ fileName, fileObj: this.exportFromFactoryOutgoFile.file });
            }
            if (newValue.type === RequestStatusEnum.ERROR) {
                notification(
                    this.$dialog.notify.error,
                    this.$t('outgo.exporttitles.error', newValue.message)
                );
                return RequestStatusEnum.ERROR;
            }
        },
    },
};
</script>